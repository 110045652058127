import * as React from 'react';
import { withLayout } from '../../components/layout.tsx';
import { H1 } from '../../components/mdx_blocks/header';
import { StaticImage } from 'gatsby-plugin-image';

function NondestructiveEvaluation() {
  return (
    <>
      <H1>Nondestructive Evaluation</H1>
      <p>
        Salvus can efficiently model ultrasound propagation in materials
        relevant for structural health monitoring. With the included
        Python-based toolbox for digitial twin creation, this opens up the
        application wavefield-based optimal experimental design and
        full-waveform inversion to applications in nondestructive evaluation.
        Below, we see a fully 3-D elastic ultrasound wavefield propagating
        through reinforced concrete. Simulations like this one can be run in a
        matter of seconds on a single consumer-grade workstation.
      </p>
      <StaticImage
        src="../../images/applications/reinforced_concrete.png"
        alt="Waves in reinforced concrete."
        width={1200}
        placeholder="tracedSVG"
      />
    </>
  );
}

export default withLayout(NondestructiveEvaluation);
